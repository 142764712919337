<template>
    <div id="app" class="content grid-wrap" ref="shakedInputArea">
      <DisabledCookieBlock
        v-if="showDisabledCookieBlock"
      ></DisabledCookieBlock>
      <template v-else>
        <div class="content__form grid-row" v-if="!isRepeatConfirmation">
            <div class="content__form__header-wrap">
                <div class="header-left">
                    <h1 class="header-title">{{ $t('header.signin') }}</h1>
                </div>
                <div class="header-rigth">
                    <span
                        class="new-account-description"
                    >{{ $t('header.account.createDescription') }}</span>
                    <a
                        :href="getRegisterUrl"
                        class="button button_size_medium button_type_standart button_family_secondary button_color_primary"
                    >
                        <div class="button__text">{{ $t('header.account.create') }}</div>
                    </a>
                </div>
            </div>
            <div
              v-if="visibilityInfoRegistration"
              class="content__form__text text-md"
              v-html="$t('login.infoRegistration')"
            ></div>
            <template v-if="visibleAuthSoc">
                <form :action="actions.externalLoginUrl" method="post">
                    <div class="ext-auth-btns">
                        <base-button
                          v-if="visibleAuthSocVk"
                          family="secondary"
                          class="button button_size_medium button_type_standart button_color_primary new-account ext-auth-btn"
                          value="VKontakte"
                          name="provider"
                          type="submit"
                        >
                            <icon-vkontakte-color />
                            Вконтакте
                        </base-button>
                        <base-button
                          v-if="visibleAuthSocGoogle"
                          family="secondary"
                          class="button button_size_medium button_type_standart button_color_primary ext-auth-btn"
                          value="Google"
                          name="provider"
                          type="submit"
                        >
                            <icon-google-color />
                            Google
                        </base-button>
                        <base-button
                          v-if="visibleAuthSocSso"
                          family="secondary"
                          class="button button_size_medium button_type_standart button_color_primary ext-auth-btn"
                          value="Sso"
                          name="provider"
                          type="submit"
                        >
                            <icon-gazprom-color />
                            ГИД
                        </base-button>
                    </div>
                </form>
                <form-divider position="top">
                    <template v-slot>{{ $t('form.separator')}}</template>
                </form-divider>
            </template>
            <base-alert
                v-if="showAlert"
                :title="alertTitle"
                :text="alertMessage"
                :hasIcon="true"
                :state="alertState"
                :rigthLabel="remainingText"
                class="choice-group__alert"
            ></base-alert>
            <div
                class="alert choice-group__alert alert_state_warning alert_type_icon-title-text"
                v-if="emailNotConfirmed"
            >
                <div class="alert__wrap">
                    <div class="alert__icon"></div>
                    <div class="alert__message">
                        <div class="alert__title">{{ $t('errors.emailNotConfirmedTitle') }}</div>
                        <div class="alert__text">
                            {{ $t('errors.emailNotConfirmed1') }}
                            <a
                                v-on:click="repeatConfirmation"
                            >{{ $t('errors.emailNotConfirmed2') }}</a>.
                        </div>
                    </div>
                </div>
            </div>
            <div class="inputs">
                <email-control
                    :label="$t('form.email.label')"
                    :placeholder="$t('form.email.placeholder')"
                    :includedValidation="includedEmailValidation"
                    :hideIcon="hideEmailState"
                    v-on:input="touch"
                    v-model="email"
                    ref="emailControl"
                    position="left"
                ></email-control>
                <password-control
                    :label="$t('form.password.label')"
                    :resetLinkText="$t('form.password.reset')"
                    :includedValidation="includedPasswordValidation"
                    :hideIcon="hidePasswordState"
                    v-on:input="touch"
                    v-model="password"
                    :resetLinkUrl="actions.resetPasswordUrl"
                    ref="passwordControl"
                    position="left"
                ></password-control>
            </div>
            <base-button
                class="button_family_primary button_size_large button_type_standart button_color_primary signin"
                :loading="isLoading"
                :disabled="isDisabled"
                :action="auth"
            >
                {{$t('header.login')}}
                <template v-slot:iconRight>
                    <icon-base
                        name="vk"
                        iconColor="#ffffff"
                        strokeColor="#ffffff"
                        :width="18"
                        :height="18"
                    >
                        <icon-right />
                    </icon-base>
                </template>
            </base-button>
        </div>
        <form-page class="grid-row__size-16 repeatConfirm" v-if="isRepeatConfirmation">
            <template v-slot:titleLeft>
                <h1>{{ $t('form.successed.header') }}</h1>
            </template>
            <template v-slot:default>
                <p class="to-info">{{ $t('form.successed.info') }}</p>
                <p class="to-email">
                    <b>{{ $refs.emailControl.emailValue }}</b>
                </p>
                <div class="form-page__plate">
                    <p>{{ $t('form.successed.warning.title') }}</p>
                    <ul>
                        <li>{{ $t('form.successed.warning.case') }}</li>
                    </ul>
                </div>
            </template>
        </form-page>
        <AgreementModal ref="agreementModal" @accepted="afterAcceptAgreement"></AgreementModal>
      </template>
      <DisabledCookieAlert ref="disabledCookieAlert" v-show="!showDisabledCookieBlock"></DisabledCookieAlert>
    </div>
</template>

<script>
import BaseButton from "./../../assets/components/BaseButton";
import EmailControl from "./../../assets/components/EmailControl";
import FormDivider from "./../../assets/components/FormDivider";
import FormPage from "./../../assets/components/FormPage";
import PasswordControl from "./../../assets/components/PasswordControl";
import BaseAlert from "./../../assets/components/BaseAlert";
import IconBase from "./../../assets/component-icons/IconBase.vue";
import IconVkontakteColor from "./../../assets/component-icons/Brand&Logos/IconVkontakteColor.vue";
import IconGoogleColor from "./../../assets/component-icons/Brand&Logos/IconGoogleColor.vue";
import IconGazpromColor from "./../../assets/component-icons/Brand&Logos/IconGazpromColor.vue";
import IconRight from "./../../assets/component-icons/Directional/IconRight.vue";
import ShakeErrorInputMixin from "./../../assets/mixins/ShakeErrorInputMixin";
import AgreementModal from './sections/AgreementModal.vue';
import DisabledCookieAlert from '../../assets/components/DisabledCookieAlert/DisabledCookieAlert.vue';
import DisabledCookieBlock from '../../assets/components/DisabledCookieBlock/DisabledCookieBlock.vue';

import axios from "axios";

function getQueryParam(item) {
  const value = window.location.search.match(new RegExp(`[?&]${item}=([^&]*)(&?)`, 'i'));
  return value ? value[1] : value;
}

export default {
    name: "Login",
    components: {
        BaseButton,
        IconBase,
        IconVkontakteColor,
        IconGoogleColor,
        IconGazpromColor,
        EmailControl,
        FormDivider,
        PasswordControl,
        IconRight,
        BaseAlert,
        FormPage,
        AgreementModal,
        DisabledCookieAlert,
        DisabledCookieBlock,
    },
    mixins: [ShakeErrorInputMixin],
    i18n: {
      messages: {
        ru: {
          login_tip: 'Если вы зарегистрированы на&nbsp;платформе russiarunning.com, то используйте данные RussiaRunning для&nbsp;входа в&nbsp;личный кабинет',
        },
        en: {
          login_tip: 'If you are registered on the&nbsp;russiarunning.com platform, then use the&nbsp;RussiaRunning data to&nbsp;enter your personal account',
        },
      },
    },
    data() {
        const { actions, returnUrl, registerReturnUrl } = window.vm;

        return {
            showDisabledCookieBlock: false,
            self: this,
            isLoading: false,
            isDisabled: false,
            isLockedOut: false,
            showAlert: false,
            emailNotConfirmed: false,
            isRepeatConfirmation: false,
            alertState: "warning",
            alertTitle: "",
            alertMessage: "",
            redirectUrl: null,
            canValidate: false,
            success: false,
            email: {},
            password: {},
            remainingSeconds: null,
            remainingText: null,
            includedEmailValidation: {
                required: this.$t("validation.email.require"),
                email: this.$t("validation.email.error"),
            },
            includedPasswordValidation: {
                required: this.$t("validation.password.require"),
            },
            actions: actions,
            returnUrl: returnUrl,
            registerReturnUrl: registerReturnUrl,
        };
    },
    methods: {
        async auth() {
            if (this.$refs.disabledCookieAlert.isActive) {
              this.showDisabledCookieBlock = true;
              return;
            }
            this.canValidate = true;
            if (this.isDisabled) {
                return;
            }

            if (!this.checkFields()) {
                return;
            }

            if (this.isLoading) return;
            this.isLoading = true;
            try {
                const authFormData = new FormData();
                authFormData.set("username", this.email.value);
                authFormData.set("password", this.password.value);
                authFormData.set("rememberMe", true);

                if (this.returnUrl) {
                    authFormData.set("returnUrl", this.returnUrl);
                }

                const authResult = axios
                    .post(this.actions.loginUrl, authFormData)
                    .then((response) => {
                        const { data } = response;

                        this.showAlert = false;
                        this.alertTitle = "";
                        this.alertMessage = "";
                        this.redirectUrl = null;

                        if (data.Success) {
                            this.success = true;
                            this.redirectUrl = data.RedirectUrl || '/Account';

                            if (!data.HasAgreement) {
                                this.$refs.agreementModal.open();
                            } else {
                                window.location.href = this.redirectUrl;
                            }
                        } else {
                            this.isLoading = false;
                            if (data.EmailNotConfirmed) {
                                this.emailNotConfirmed = true;
                            } else {
                                this.showAlert = true;
                                this.alertState = data.IsWarning
                                    ? "warning"
                                    : "danger";
                                this.alertTitle = data.ErrorTitle ?? "error";
                                this.alertMessage =
                                    data.ErrorMessage ?? "error has occurred";

                                if (data.IsLockedOut && data.RemainingSeconds) {
                                    this.isLockedOut = true;
                                    if (this.remainingSeconds === null) {
                                        this.remainingSeconds =
                                            data.RemainingSeconds;
                                        this.remainingText = this.formatMMss(
                                            this.remainingSeconds
                                        );
                                        setInterval(() => {
                                            if (
                                                this.showAlert &&
                                                this.isLockedOut
                                            ) {
                                                this.remainingSeconds =
                                                    this.remainingSeconds - 1;
                                                if (this.remainingSeconds > 0) {
                                                    this.remainingText = this.formatMMss(
                                                        this.remainingSeconds
                                                    );
                                                } else {
                                                    this.showAlert = false;
                                                    this.remainingText = null;
                                                    this.remainingSeconds ===
                                                        null;
                                                    this.alertTitle = "";
                                                    this.alertMessage = "";
                                                }
                                            }
                                        }, 1000);
                                    } else {
                                        this.remainingSeconds =
                                            data.RemainingSeconds;
                                        this.remainingText = this.formatMMss(
                                            this.remainingSeconds
                                        );
                                    }
                                } else {
                                    this.isLockedOut = false;
                                }
                            }
                        }
                    });

                await Promise.all([authResult]);
            } catch (e) {
                console.log("error on login");
                this.isLoading = false;
            }
        },
        afterAcceptAgreement() {
            window.location.href = this.redirectUrl;
        },
        formatMMss(seconds) {
            return new Date().clearTime().addSeconds(seconds).toString("mm:ss");
        },
        touch() {
            if (this.email.value && this.password.value) {
                const { isValid, canValidate } = this;
                this.isDisabled = canValidate && !isValid;
            }
        },
        checkFields() {
            const { $refs, shakeErrorInputs } = this;

            $refs.emailControl.touch();
            $refs.passwordControl.touch();

            const { isValid } = this;
            this.isDisabled = !isValid;

            if (!isValid) {
                shakeErrorInputs();
            }

            return isValid;
        },
        async repeatConfirmation() {
            const data = new FormData();
            data.set("email", this.email.value);
            const result = axios
                .post(this.actions.repeatConfirmationUrl, data)
                .then(() => {
                    this.isRepeatConfirmation = true;
                    let b = window.BackButton;
                    if (b) {
                        b.setTitle(this.$t("backTo.login"));
                        b.setCallback(
                            function () {
                                b.setActualState();
                                this.$nextTick(() => {
                                    this.email = {};
                                    this.password = {};
                                    this.isRepeatConfirmation = false;
                                    this.emailNotConfirmed = false;
                                    this.canValidate = false;
                                });
                            }.bind(this)
                        );
                    }
                });
            await Promise.all([result]);
        },
    },
    computed: {
        isValid() {
            const isValid = this.email.isValid && this.password.isValid;
            return isValid;
        },
        hideEmailState() {
            if (!this.canValidate) {
                return true;
            }
            return this.$refs.emailControl.isValid && !this.success;
        },
        hidePasswordState() {
            if (!this.canValidate) {
                return true;
            }
            return this.$refs.passwordControl.isValid && !this.success;
        },
        getRegisterUrl() {
            if (!this.registerReturnUrl) {
                return this.actions.registerUrl;
            }
            return (
                this.actions.registerUrl +
                "?returnUrl=" +
                encodeURIComponent(this.registerReturnUrl)
            );
        },
        defaultTheme() {
            return this.$tenant === 'RR';
        },
        visibleAuthSocVk() {
            const availableTenants = ['RR'];
            return availableTenants.includes(this.$tenant);
        },
        visibleAuthSocGoogle() {
            const availableTenants = ['RR'];
            return availableTenants.includes(this.$tenant);
        },
        visibleAuthSocSso() {
            const availableTenants = ['RR'];
            return availableTenants.includes(this.$tenant);
        },
        visibleAuthSoc() {
            return this.visibleAuthSocVk || this.visibleAuthSocGoogle || this.visibleAuthSocSso;
        },
        visibilityInfoRegistration() {
          const disableTenants = ['RR'];
          console.log(this.$tenant);

          return !disableTenants.includes(this.$tenant);
        }
    },
    mounted() {
        const hasAgreement = getQueryParam('hasAgreement');
        if (hasAgreement === 'false') {
          this.redirectUrl = window.vm.returnUrl || '/Account';
          this.$refs.agreementModal.open();
        }
    },
};
</script>

<style lang="scss">
@import "../../assets/tokens/tokens.scss";
@import "../../assets/normalize.min.css";
@import "login.scss";
</style>
